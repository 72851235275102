import { Tabs, Table, Tag, Drawer, Button, Form, Input, Select, Upload } from 'antd';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Container from '../../components/Container';
import api from '../../controllers/ApiController';
import styles from './contracts.module.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import Button from '../../components/Button';

const { Option } = Select;
const ethereum_address = require('ethereum-address');

function App() {

  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [contract, setContract] = useState();
  const [methods, setMethods] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState();
  const [isDisabled, setDisabled] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [uploadingPdf, setUploadingPdf] = useState(false);
  const { t } = useTranslation('contracts');


  const fetchContracts = () => {
    setLoading(true);
    api.get('/v1/contracts/').then(res => {
      setData(res.data)
      setLoading(false);
    }).catch(e => {

    });
  }

  const fetchMethods = () => {
    api.get('/v1/payments/methods/ALL').then(res => {
      setMethods(res.data);
    }).catch(e => {

    })
  }

  const fetchContacts = () => {
    api.get('/v1/profile/contacts').then(res => {
      const array = [];
      res.data.map(v => {
        if (v.verified) {
          array.push(v);
        }
        setContacts(array);
      })
    }).catch(e => {

    });
  }

  useEffect(() => {
    fetchContracts();
    fetchContacts();
    fetchMethods();
  }, []);

  const columns = [
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (text, record) => <div>${text}</div>,
    },
    {
      title: 'Status',
      key: 'tags',
      dataIndex: 'tags',
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {

            let color;

            switch (tag) {
              case "rejected":
                color = 'red';
                break;
              case "awaiting for payment":
                color = 'warning';
                break;
              case "sumbitted":
                color = 'warning';
                break;
              case "finished":
                color = 'green';
                break;
              default:
                color = 'grey';
            }

            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>

            );
          })}
        </>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => {
        return <Button onClick={() => setContract(record)}>View</Button>
      },
    },
  ];

  const getTags = (v) => {
    const tags = [];

    if (v.status === 0) {
      tags.push('draft');
    }

    if (v.status === 1) {
      tags.push('sumbitted');
    }

    if (v.status === 2) {
      tags.push('awaiting for payment');
    }

    if (v.status === 3) {
      tags.push('finished');
    }

    return tags;

  }

  const prepare = () => {
    return data.map(v => {
      return {
        key: v.id,
        ...v,
        tags: getTags(v)
      }
    })
  }

  const onSubmit = (values) => {
    setContract(null);
    api.post('/v1/contracts/sign', values).then(res => {
      fetchContracts();
    });
  }

  const validate = (e) => {
    if (ethereum_address.isAddress(e.target.value)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }

  const validateEmail = (e) => {
    setDisabled(!e.target.value.includes('@'));
  }


  const getPaymentField = () => {
    if (paymentMethod) {
      const method = methods.find(v => v.id === paymentMethod);
      if (method?.type === "erc20") return <Form.Item label="Wallet" name="paymentDetails" rules={[{ required: true, message: '' }]} onChange={validate}>
        <Input placeholder="" size="large" />
      </Form.Item>

      if (method?.type === "email") return <Form.Item label="Email" name="paymentDetails" rules={[{ required: true, message: '' }]} onChange={validateEmail}>
        <Input placeholder="" size="large" />
      </Form.Item>
    }
    return null;
  }

  const handleUpload = async (e, id) => {
    const formData = new FormData();
    formData.append("pdf", e.target.files[0]);
    setUploadingPdf(true);
    api.post(process.env.REACT_APP_BACKEND_URL + `/v1/contracts/pdf/upload/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      setContract((prev) => {
        return { ...prev, status: 2 }
      })
    }).catch(e => {
      //setError(e?.response?.data?.message || "Error");
    }).finally(() => {
      setUploadingPdf(false);
    })
  }

  const [form] = Form.useForm();

  const tabs = [{
    key: "Details", label: "Details", children: <div className="w-full">
      {!contacts?.length && <div style={{ marginBottom: 16 }}>Verify your <Link to="/profile/">profile</Link> to continue</div>}
      {contract?.status === 0 ? <Form
        layout="horizontal"
        form={form}
        onFinish={onSubmit}
        disabled={contract?.status !== 0}
      >
        <Form.Item label="Person" name="contactId" rules={[{ required: true, message: '' }]}>
          <Select size="large" disabled={!contacts.length} placeholder={!contacts.length ? "No avaliable persons" : ""}>
            {contacts.map(v => <Option value={v.id}>{v.firstName} {v.lastName}</Option>)}
          </Select>
        </Form.Item>

        <Form.Item label="Payment Method" name="paymentMethod" rules={[{ required: true, message: '' }]}>
          <Select onChange={setPaymentMethod} size="large">
            {methods.map(v => <Option key={`pm_${v.id}`} value={v.id}>{v.name}</Option>)}
          </Select>
        </Form.Item>
        {getPaymentField()}
        <Form.Item>
          <Button type="primary" block htmlType="submit" disabled={isDisabled} className="bg-blue-500">Submit</Button>
        </Form.Item>
      </Form> : contract?.status === 1 && <div><Button target="_blank" block href={`${process.env.REACT_APP_BACKEND_URL}/v1/contracts/pdf/create/${contract?.id}`}>Download contract</Button><div>

        <label htmlFor={`upload_pdf`} className="bg-stone-100 w-full flex items-center justify-center rounded-md p-8 mt-4">
          <input id={`upload_pdf`} name="file" type="file" accept=".pdf" onChange={(e) => handleUpload(e, contract?.id)} title="Upload" disabled={false} className="hidden"/>
          {uploadingPdf ? 'Uploading...' : `Upload Signed PDF`}
        </label>

      </div></div>}

      {contract?.status === 2 && <div>The contract has been successfully uploaded. Please wait for the contract to complete.</div>}

    </div>
  },
  {
    key: "Tracks",
    label: "Tracks",
    children: <table >
      <tbody>
        {contract?.Offers && contract.Offers.map(offer => <tr>
          <td style={{ width: '100%' }}>{offer.Track.title}</td>
          <td>${offer.price}</td>
        </tr>)}
        <tr style={{ backgroundColor: 'lightgrey' }}>
          <td></td>
          <td><b>${contract?.price}</b></td>
        </tr>
      </tbody>
    </table >,
  }]

  return <Container title={t('TITLE')} isLoading={isLoading}>
    {data.length ? <Table columns={columns} dataSource={prepare(data)} pagination={{
      position: ['none', 'none'],
    }} /> : <p>Contracts will be available after you confirm the first offer.</p>}
    <Drawer placement="right" onClose={() => setContract(null)} open={!!contract} width={500}>
      <Tabs items={tabs} />
    </Drawer >
  </Container>
}

export default App;
